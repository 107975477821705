import { createContext } from "react";
import { useState, useCallback } from "react";
import axios from "axios";
import alertify from "alertifyjs";
import { torrentsCompleted } from "../utils/utility";

export const TorrentContext = createContext(null);

export const TorrentProvider = (props) => {
  const [torrents, setTorrents] = useState(null);
  const [online, setOnline] = useState(true);
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(1000);

  const getTorrents = useCallback(async () => {
    try {
      const response = await axios.get("/torrents");
      const data = response.data.torrents;
      setTorrents(data);
      setOnline(true);
      setLoading(false);
      setDelay(torrentsCompleted(data) ? 1000 : 100);
    } catch (error) {
      if (error) setOnline(false);
    }
  }, []);

  const deleteTorrent = async (item) => {
    try {
      await axios.delete(`/torrents/${item.hash}`);
      setTorrents(torrents.filter((t) => t.hash !== item.hash));
      alertify.success("Torrent supprimé");
    } catch (error) {}
  };

  const updateTorrent = (torrent) => {
    setTorrents((prev) => {
      return [...prev, torrent];
    });
  };

  const addFilesTorrent = async (files) => {
    const formData = new FormData();
    for (const file of files) {
      formData.append("torrents", file);
    }

    try {
      const response = await axios.post("/torrents/file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { torrents } = response.data;
      for (const torrent of torrents) {
        if (!torrent.hash) {
          alertify.error("Torrent non ajouté");
          continue;
        }
        updateTorrent(torrent);
        alertify.success("Torrent ajouté");
      }
    } catch (error) {}
  };

  const addMagnetTorrent = async (data) => {
    try {
      const response = await axios.post("/torrents/magnet", data);
      const { torrent } = response.data;
      if (torrent.hash) {
        updateTorrent(torrent);
        alertify.success("Torrent ajouté");
      } else {
        alertify.error("Torrent non ajouté");
      }
    } catch (error) {}
  };

  return (
    <TorrentContext.Provider
      value={{
        torrents,
        deleteTorrent,
        online,
        getTorrents,
        addFilesTorrent,
        addMagnetTorrent,
        loading,
        delay,
      }}
    >
      {props.children}
    </TorrentContext.Provider>
  );
};
