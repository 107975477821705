import "./Loading.css";

const Loading = () => {
  return (
    <div className="loading">
      Chargement...
      <div className="lds-dual-ring" style={{ marginTop: "1rem" }}></div>
    </div>
  );
};

export default Loading;
