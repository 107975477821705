import "./TorrentList.css";
import {
  Fragment,
  useEffect,
  useRef,
  useContext,
  useState,
  useMemo,
} from "react";
import {
  formatBytes,
  getRatio,
  getPercent,
  getTimeLeft,
} from "../utils/utility";
import { TorrentContext } from "../contexts/Torrent";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";

const TorrentList = () => {
  const [confirm, setConfirm] = useState({});
  const refreshId = useRef();
  const {
    torrents,
    getTorrents,
    deleteTorrent,
    online,
    loading,
    delay,
  } = useContext(TorrentContext);
  const confirmId = useMemo(() => [], []);

  useEffect(() => {
    getTorrents();

    return () => {
      for (const id of confirmId) {
        clearTimeout(id);
      }
    };
  }, [getTorrents, confirmId]);

  useEffect(() => {
    refreshId.current = setInterval(getTorrents, delay);

    return () => {
      clearInterval(refreshId.current);
    };
  }, [getTorrents, delay]);

  const deleteHandler = async (torrent) => {
    const { id } = torrent;

    if (confirm[id]) {
      await deleteTorrent(torrent);
    } else {
      const timerId = setTimeout(() => {
        setConfirm((prev) => {
          return { ...prev, [id]: false };
        });
      }, 2000);
      confirmId.push(timerId);

      setConfirm((prev) => {
        return { ...prev, [id]: true };
      });
    }
  };

  if (!online)
    return <div className="info">Le programme rtorrent ne répond pas.</div>;

  if (loading) return <Loading />;

  if (torrents && torrents.length === 0)
    return <div className="info">Aucuns torrents disponibles.</div>;

  return (
    <div className="torrents">
      <div className="torrents-header" />
      <div className="torrents-header">NOM</div>
      <div className="torrents-header">TAILLE</div>
      <div className="torrents-header">REÇU</div>
      <div className="torrents-header">TÉLÉCHARGÉ</div>
      <div className="torrents-header">ENVOYÉ</div>
      <div className="torrents-header">RATIO</div>
      <div className="torrents-header">RÉCEPTION</div>
      <div className="torrents-header">TEMPS ESTIMÉ</div>

      {torrents &&
        torrents.map((torrent) => {
          const { id, name, size, download, sent, reception } = torrent;

          return (
            <Fragment key={id}>
              <div className="torrents-data delete-button">
                <button
                  className="button-icon"
                  style={{ fontSize: "1rem" }}
                  onClick={() => deleteHandler(torrent)}
                  title="Supprimer"
                >
                  {!confirm[id] ? (
                    <i className="fas fa-trash-alt" />
                  ) : (
                    <i className="fas fa-exclamation-circle confirm-button" />
                  )}
                </button>
              </div>
              <div className="torrents-data wrap">
                <Link to={`/data/${name}`}>{name ? name : "?"}</Link>
              </div>
              <div className="torrents-data">
                {size ? formatBytes(size) : "?"}
              </div>
              <div className="torrents-data">{getPercent(torrent)}%</div>
              <div className="torrents-data">
                {download !== undefined ? formatBytes(download) : "?"}
              </div>
              <div className="torrents-data">
                {sent !== undefined ? formatBytes(sent) : "?"}
              </div>
              <div className="torrents-data">{getRatio(torrent)}</div>
              <div className="torrents-data">
                {reception !== undefined ? formatBytes(reception) : "?"}/s
              </div>
              <div className="torrents-data">{getTimeLeft(torrent)}</div>
            </Fragment>
          );
        })}
    </div>
  );
};

export default TorrentList;
