import axios from "axios";
import useForm from "../hooks/form";
import alertify from "alertifyjs";
import { useHistory } from "react-router-dom";

const Register = () => {
  const initialState = { name: "", pid: "" };
  const [state, changeHandler] = useForm(initialState);
  const history = useHistory();

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      await axios.post("/users", state);
      history.push("/");
    } catch (error) {
      console.log(error);
      if (error) alertify.alert("Erreur", error.data.message);
    }
  };

  return (
    <form onSubmit={handleRegister}>
      <h1>Register</h1>

      <label htmlFor="name">Username</label>
      <input id="name" value={state.name} onChange={changeHandler}></input>

      <label htmlFor="pid">PID</label>
      <input id="pid" value={state.pid} onChange={changeHandler}></input>

      <button type="submit">Register</button>
    </form>
  );
};

export default Register;
