import axios from "axios";
import { useState, useContext, Fragment, useRef } from "react";
import { UserContext } from "../contexts/User";
import useForm from "../hooks/form";
import alertify from "alertifyjs";

const Login = () => {
  const initialState = { name: "", password: "", repeatPassword: "" };
  const [state, changeHandler, setState] = useForm(initialState);
  const [loginMode, setLoginMode] = useState(true);
  const { login } = useContext(UserContext);
  const uid = useRef();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (loginMode) {
      try {
        const response = await axios.post("/auth", state);
        if (response.data.userId) {
          uid.current = response.data.userId;
          setState(initialState);
          setLoginMode(false);
        } else {
          const { token, user } = response.data;
          login(token, user);
        }
      } catch (error) {
        if (error)
          alertify.alert(
            "Erreur",
            "Mauvais nom d'utilisateur ou mot de passe."
          );
        else alertify.alert("Erreur", "Le serveur ne répond pas.");
      }
    } else {
      if (state.password !== state.repeatPassword)
        return alertify.alert(
          "Erreur",
          "Les mots de passe ne sont pas identiques."
        );

      try {
        const response = await axios.post(`/users/${uid.current}`, state);
        const { token, user } = response.data;
        login(token, user);
      } catch (error) {
        alertify.alert("Erreur", "Mauvais mot de passe.");
      }
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <h1>{loginMode ? "Connexion" : "Choix du mot de passe"}</h1>

      {loginMode && (
        <Fragment>
          <label htmlFor="name">Nom d'utilisateur</label>
          <input id="name" value={state.name} onChange={changeHandler}></input>
        </Fragment>
      )}

      <label htmlFor="password">Mot de passe</label>
      <input
        id="password"
        value={state.password}
        onChange={changeHandler}
        type="password"
      />

      {!loginMode && (
        <Fragment>
          <label htmlFor="repeatPassword">Confirmer mot de passe</label>
          <input
            id="repeatPassword"
            value={state.repeatPassword}
            onChange={changeHandler}
            type="password"
          />
        </Fragment>
      )}

      <button type="submit">Se connecter</button>
    </form>
  );
};

export default Login;
