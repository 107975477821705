const round = (num, precision) => {
  return +(Math.round(num + `e+${precision}`) + `e-${precision}`);
};

const formatBytes = (bytes) => {
  const suffix = ["o", "Ko", "Mo", "Go", "To"];
  let i;
  let dblSByte = bytes;

  for (i = 0; i < suffix.length && bytes >= 1024; bytes /= 1024) {
    dblSByte = bytes / 1024.0;
    i++;
  }

  dblSByte = round(dblSByte, 2);
  return `${dblSByte} ${suffix[i]}`;
};

const getRatio = (torrent) => {
  if (!torrent.download || !torrent.sent) return 0;
  if (torrent.download === 0) return 0;

  let ratio = torrent.sent / torrent.download;
  ratio = round(ratio, 1);
  return ratio;
};

const getPercent = (torrent) => {
  if (!torrent.download || !torrent.size) return 0;
  if (torrent.download > torrent.size) return "?";

  let percent = Math.floor((torrent.download / torrent.size) * 100);
  return percent;
};

const getTimeLeft = (torrent) => {
  if (torrent.download === torrent.size) return "∞";

  if (torrent.size === 0 || torrent.reception === 0) return "?";

  const totalSec = (torrent.size - torrent.download) / torrent.reception;
  const date = new Date(0, 0, 0, 0, 0, totalSec);
  let time = "";

  const day = date.getDay();
  const hour = date.getHours();
  const min = date.getMinutes();
  const sec = date.getSeconds();

  if (day > 0) time += day + "j ";
  if (hour > 0) time += hour + "h ";
  if (min > 0) time += min + "m ";
  if (sec > 0) time += sec + "s ";
  if (!time) return "∞";

  time = time.slice(0, -1);

  return time;
};

const torrentsCompleted = (torrents) => {
  for (const torrent of torrents) {
    if (torrent.download !== torrent.size) {
      return false;
    }
  }

  return true;
};

export { formatBytes, getRatio, getPercent, getTimeLeft, torrentsCompleted };
