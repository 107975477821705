import { useState } from "react";

const useForm = (initialState) => {
  const [state, setState] = useState(initialState);

  const changeHandler = (event) => {
    setState((prev) => {
      return {
        ...prev,
        [event.target.id]: event.target.value,
      };
    });
  };

  return [state, changeHandler, setState];
};

export default useForm;
