import { createContext } from "react";
import { useState, useEffect } from "react";
import axios from "axios";

export const UserContext = createContext(null);

export const UserProvider = (props) => {
  const [check, setCheck] = useState(false);
  const [user, setUser] = useState(null);
  const [canRegister, setCanRegister] = useState(false);

  const tokenKey = "token";
  const tokenHeaderKey = "x-access-token";

  const login = (token, user) => {
    user.token = token;
    setUser(user);
    localStorage.setItem(tokenKey, token);
    axios.defaults.headers.common[tokenHeaderKey] = token;
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem(tokenKey);
    axios.defaults.headers.common[tokenHeaderKey] = null;
  };

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem(tokenKey);
      try {
        if (token) {
          const response = await axios.get("/users/me", {
            headers: {
              [tokenHeaderKey]: token,
            },
          });
          login(token, response.data.user);
        } else {
          const response = await axios.get("/auth/can-register");
          setCanRegister(response.data.value);
        }
      } catch (error) {
        if (error) logout();
      }

      setCheck(true);
    };

    checkAuth();
  }, []);

  return (
    <UserContext.Provider value={{ canRegister, user, login, logout }}>
      {check && props.children}
    </UserContext.Provider>
  );
};
