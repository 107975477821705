import "./Data.css";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { UserContext } from "../contexts/User";
import path from "path";
import { Fragment } from "react";
import Loading from "../components/Loading";

const Data = () => {
  const location = useLocation();
  const [data, setData] = useState({ folders: [], files: [] });
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    const request = async () => {
      try {
        let currentPath = location.pathname.substring(5);
        if (!currentPath) currentPath = "/";
        const response = await axios.get(
          `/data?path=${encodeURIComponent(currentPath)}`
        );
        const { folders, files, file } = response.data;
        if (folders && files) {
          if (currentPath !== "/") folders.unshift("../");
          setData(response.data);
        } else if (file) {
          setData({ folders: ["../"], files: [] });
          window.location = `${
            process.env.REACT_APP_URL
          }/data?file=${encodeURIComponent(file)}&token=${user.token}`;
          if (history.length > 2) history.goBack();
        }
        setHasError(false);
      } catch (error) {
        if (error) setHasError(true);
      }
      setLoading(false);
    };

    request();
  }, [location.pathname, user.token, history]);

  const getPath = (newPath) => {
    return path.join(location.pathname, newPath);
  };

  const { folders, files } = data;

  if (loading) return <Loading />;

  return (
    <div className="data-container">
      <div>
        <Fragment>
          <button className="button-icon" onClick={() => history.push("/")}>
            <i className="fas fa-arrow-left" />
          </button>

          <div className="data-separator" />
        </Fragment>

        {hasError && <div className="data-info">Chemin introuvable.</div>}

        {!hasError && folders.length === 0 && files.length === 0 && (
          <div className="data-info">Aucunes données</div>
        )}

        {folders.length > 0 &&
          folders.map((item) => {
            return (
              <Link key={item} to={getPath(item)} className="data-folder wrap">
                {item}
              </Link>
            );
          })}

        {folders.length > 0 && files.length > 0 && (
          <div className="data-separator" />
        )}

        {files &&
          files.map((item) => {
            return (
              <Link key={item} to={getPath(item)} className="data-file wrap">
                {item}
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default Data;
