import { useEffect, useState, useCallback } from "react";

const useVersion = () => {
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  const VERSION_KEY = "APP_VERSION";

  const getVersion = () => {
    return localStorage.getItem(VERSION_KEY);
  };

  const setVersion = (version) => {
    localStorage.setItem(VERSION_KEY, version);
  };

  const clearCache = async () => {
    if ("caches" in window) {
      const cacheKeys = await window.caches.keys();
      await Promise.all(cacheKeys.map(window.caches.delete));
    }

    window.location.reload();
  };

  const fetchVersion = useCallback(async () => {
    try {
      const response = await fetch("/version");
      const latestVersion = await response.text();
      const currentVersion = getVersion();
      if (currentVersion !== latestVersion) {
        setVersion(latestVersion);
        await clearCache();
      } else {
        setIsLatestVersion(true);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchVersion();
  }, [fetchVersion]);

  return [isLatestVersion];
};

export default useVersion;
