import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useContext } from "react";

import TorrentList from "./pages/TorrentList";
import Navbar from "./components/Navbar";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Data from "./pages/Data";

import { UserContext } from "./contexts/User";
import { TorrentContext } from "./contexts/Torrent";

import useVersion from "./hooks/version";

const App = () => {
  const [isLatestVersion] = useVersion();

  const { user, canRegister } = useContext(UserContext);
  const { addFilesTorrent } = useContext(TorrentContext);

  let routes;

  if (user) {
    routes = (
      <Switch>
        <Route exact path="/" component={TorrentList} />
        <Route path="/data" component={Data} />
        {user.isAdmin && <Route exact path="/register" component={Register} />}
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/" component={Login} />
        {canRegister && <Route exact path="/register" component={Register} />}
        <Redirect to="/" />
      </Switch>
    );
  }

  const dragHandler = (event) => {
    event.preventDefault();
  };

  const dropHandler = (event) => {
    event.preventDefault();
    if (user) {
      const files = event.dataTransfer.files;
      addFilesTorrent(files);
    }
  };

  if (!isLatestVersion) return <div></div>;

  return (
    <Router>
      <div className="container" onDrop={dropHandler} onDragOver={dragHandler}>
        <div>
          <Navbar />
        </div>
        <div>{routes}</div>
      </div>
    </Router>
  );
};

export default App;
