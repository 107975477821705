import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import axios from "axios";
import alertify from "alertifyjs";

import { UserProvider } from "./contexts/User";
import { TorrentProvider } from "./contexts/Torrent";

axios.defaults.baseURL = process.env.REACT_APP_URL;
axios.interceptors.response.use(null, (error) => {
  return Promise.reject(error.response);
});

alertify.defaults = {
  ...alertify.defaults,
  movable: false,
  transition: "fade",
};

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <TorrentProvider>
        <App />
      </TorrentProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
