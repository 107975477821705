import "./Navbar.css";
import { useContext, Fragment, useRef } from "react";
import { UserContext } from "../contexts/User";
import { Link, useHistory } from "react-router-dom";
import { TorrentContext } from "../contexts/Torrent";
import useForm from "../hooks/form";

const Navbar = (props) => {
  const { user, logout } = useContext(UserContext);
  const history = useHistory();
  const { addFilesTorrent, addMagnetTorrent, online } = useContext(
    TorrentContext
  );
  const filesSelector = useRef();

  const initialState = { magnet: "" };
  const [state, changeHandler, setState] = useForm(initialState);

  const handleDisconnect = () => {
    logout();
    history.push("/");
  };

  const filesSelectorHandler = (event) => {
    addFilesTorrent(event.target.files);
  };

  const addTorrent = () => {
    if (state.magnet) {
      addMagnetTorrent(state);
      setState(initialState);
    } else {
      filesSelector.current.click();
    }
  };

  const keyDownHandler = (event) => {
    if (event.key === "Enter" && state.magnet) {
      addMagnetTorrent(state);
      setState(initialState);
    }
  };

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-brand">
        Seedbox
      </Link>
      {user && <span className="navbar-name">{user.name}</span>}
      <ul className="navbar-nav">
        {user && (
          <Fragment>
            {online && (
              <Fragment>
                <input
                  type="file"
                  accept=".torrent"
                  style={{ display: "none" }}
                  ref={filesSelector}
                  onChange={filesSelectorHandler}
                  multiple
                />
                <li className="nav-item">
                  <button
                    className="nav-button"
                    onClick={addTorrent}
                    title="Ajouter torrent"
                  >
                    <i className="fas fa-plus" />
                  </button>
                </li>
                <li className="nav-item">
                  <input
                    id="magnet"
                    className="nav-input"
                    placeholder="Lien magnet"
                    value={state.magnet}
                    onChange={changeHandler}
                    onKeyDown={keyDownHandler}
                  />
                </li>
              </Fragment>
            )}
            <li className="nav-item">
              <Link to="/data" className="nav-link" title="Données">
                <i className="fas fa-database" />
              </Link>
            </li>
            <li className="nav-item">
              <button
                className="nav-button"
                onClick={handleDisconnect}
                title="Se déconnecter"
              >
                <i className="fas fa-sign-out-alt" />
              </button>
            </li>
          </Fragment>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
